import type { IconProps } from '../types';
const LogoFpDefault = ({ 'aria-label': ariaLabel, className, testID }: IconProps) => {
  return (
    <svg
      width={116}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={true}
    >
      <path
        d="M28 4.62h9.062v2.185h-6.555v4.339h6.02v2.186h-6.02v6.289h-2.508M46.852 14.22c0-2.08-1.307-3.536-3.214-3.536-1.928 0-3.214 1.457-3.214 3.535 0 2.079 1.286 3.536 3.214 3.536 1.907 0 3.214-1.457 3.214-3.536Zm-8.785 0c0-3.236 2.314-5.572 5.571-5.572 3.235 0 5.571 2.336 5.571 5.571 0 3.236-2.336 5.57-5.57 5.57-3.258 0-5.572-2.334-5.572-5.57ZM59.262 14.22c0-2.08-1.307-3.536-3.214-3.536-1.929 0-3.214 1.457-3.214 3.536 0 2.078 1.285 3.535 3.214 3.535 1.907 0 3.214-1.457 3.214-3.535Zm-8.785 0c0-3.236 2.314-5.571 5.57-5.571 3.236 0 5.571 2.336 5.571 5.57 0 3.236-2.335 5.572-5.57 5.572-3.258 0-5.572-2.336-5.57-5.571ZM79.99 14.22c0-2.142-1.243-3.535-3.128-3.535s-3.129 1.393-3.129 3.535c0 2.121 1.244 3.536 3.129 3.536s3.128-1.415 3.128-3.536Zm-8.443-5.4h2.336v1.372c.6-.878 1.864-1.543 3.365-1.543 3.192 0 5.078 2.314 5.078 5.572-.002 3.256-1.972 5.57-5.207 5.57-1.308 0-2.572-.557-3.236-1.414v5.142h-2.336M92.718 8.821h2.336l-.002 10.799h-2.334V8.82Zm-.344-3.3c0-.857.643-1.521 1.5-1.521.878 0 1.544.664 1.544 1.521 0 .836-.666 1.5-1.544 1.5-.857 0-1.5-.664-1.5-1.5ZM97.863 8.821h2.336v1.8c.727-1.157 1.97-1.971 3.556-1.971 2.292 0 3.9 1.521 3.9 3.857v7.113h-2.336l.001-6.577c0-1.35-.836-2.293-2.207-2.293-1.822 0-2.914 1.542-2.914 4.135l-.002 4.735h-2.334M90.941 8.795a3.804 3.804 0 0 0-1.066-.145c-1.306 0-2.72.942-3.299 2.228V8.821H84.24V19.62h2.336v-4.028c0-3.342 1.393-4.757 2.979-4.757.49 0 1 .085 1.386.255M69.282 17.438c-.406.193-.96.275-1.367.275-.878 0-1.478-.558-1.478-1.63v-5.27h2.838V8.821l-2.838-.001V5.606h-2.335V8.82h-1.886v1.993h1.886v5.4c0 2.464 1.521 3.578 3.556 3.578.543 0 1.145-.092 1.624-.233M115.932 17.44c-.406.192-.961.274-1.368.274-.878 0-1.479-.558-1.479-1.629l.002-5.27h2.836V8.821h-2.836V5.607h-2.336V8.82h-1.886v1.993h1.886v5.4c0 2.464 1.521 3.578 3.556 3.578a6.09 6.09 0 0 0 1.625-.232M12 15h4v-3.54c-.589.342-1.27.54-2 .54a4 4 0 1 1 0-8c.73 0 1.411.199 2 .54V0H0v24h7v-4a5 5 0 0 1 5-5Z"
        fill="#190C49"
      />
    </svg>
  );
};
export default LogoFpDefault;
